<template>
  <div class="charge page">
    <Nav></Nav>
    <main class="wrap">
      <div class="content" v-html="content"></div>
    </main>
    <foot></foot>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import foot from "@/components/foot.vue";
export default {
  components: {
    foot,
    Nav,
  },
  data() {
    return {
      content: "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    //请求数据
    loadData() {
      //banner广告
      this.api
        .getArticle({
          id: 71,
        })
        .then((res) => {
          this.content = res.description;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less">
.content{
  padding-top: 30px;
}
</style>